<script>
import axios from 'axios'
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * transaction Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Transaction",
      submitted: false,
      showmodal: false,
      trx:[],
      startDate:'',
      endDate:'',
      f_name:'',
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      data:[],
      invs:[],
      inv:[],
      fields: [
        { key: "create_at", label:'Created' },
        { key: "invoice_num", label:'ID Invoice' },
        { key: "sender_name", label:"Sender Name" },
        { key: "recipient_name", label:"Recipient Name" },
        { key: "des_country", label:"Destination Country" },
        { key: "bank_sendto", label : "Bank Name" },
        { key: "primary", label:"Acc Number" },
        { key: "amount", label:"Send Amount" },
        { key: "status", label:"Status" },
        { key: "action" },
        // { key: "invoice" },
        // { key: "detail" }
      ],
      isBusy: false,
    };
  },
  computed: {
    rows() {
      return this.trx.length;
    }
  },
  mounted() {
    this.totalRows = this.trx.length;
  },
  methods: {
    onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    hideModal() {
        this.submitted = false;
        this.showmodal = false;
    },
    getTransactionAFF(){
      this.isBusy = true;
       let param = `0/0`;
      if (this.startDate && this.endDate) {
        param = `${this.startDate}/${this.endDate}`;
      }
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-trx-aff/${id}/${param}`)
      .then((res) => {
        this.isBusy = false;
          this.trx = res.data.transaction
          this.trx.forEach(function (x) {
              x.primary = `${x.acc_number ? x.acc_number : x.iban ? x.iban : x.phone_n}`
          })
          if(!JSON.parse(localStorage.getItem("user")).id){
            let Item = { key : 'admin_name'}
            this.fields.splice(7,0,Item)
          }
      })
    },
    download(e){
      let id = e.item.id_transaction;
      let url = process.env.VUE_APP_ROOT_DOWNLOAD;
      location.href=`${url}/v1/invoice/print/${id}`
    },
    detail(e){
      this.showmodal = true;
      this.trans = e.item;
      let id = e.item.invoice_num;
      axios.get(`v1/get-trx/${id}`)
        .then((res) => {
          this.invs = res.data.transaction
          this.invs.forEach(function(element) {
              element.created = `${element.create_at.substring(8,10)}-${element.create_at.substring(5,7)}-${element.create_at.substring(0,4)}`
          })
          this.inv = this.invs[0]
        })
      // this.$router.push({ name: "invoice" , params:{id:id}})
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPriceRP(value) {
      let val = (value / 1).toFixed(0).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPriceVND(value) {
      let val = (value / 1).toFixed(3).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  created () {
      this.getTransactionAFF();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <router-link to="/send" class="btn btn-primary mb-2">
                <i class="mdi mdi-plus mr-2"></i> Add Transaction
              </router-link>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                          </label>
                          <div>
                            <label class="d-inline-flex align-items-center">
                            Date 
                            <b-form-input class="form-control form-control-sm ml-3" v-model="startDate" type="date" icon="">
                            </b-form-input>
                            <b-form-input class="form-control form-control-sm" v-model="endDate" type="date" icon="">
                            </b-form-input>
                            <b-button variant="info" class="form-control form-control-sm py-1" @click="getTransactionAFF"
                              >Submit</b-button>
                          </label>
                          </div>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filter"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ml-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                        :items="trx"
                        :fields="fields"
                        striped
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        :busy="isBusy"
                        show-empty
                        fixed
                      >
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{ width: field.key === 'bank_sendto' || field.key === 'recipient_name' || field.key === 'sender_name' ? '190px' : '125px' }"
                        >
                      </template>
                          <template v-slot:cell(amount)="row">
                            <div
                            >{{row.item.currency_code}} {{ row.value %1 != 0 ? formatPrice(row.value) : formatPriceRP(row.value)}}</div>
                          </template>
                          <template v-slot:cell(status)="row">
                          <div
                            class="badge font-size-12 pr-1"
                            :class="{'badge-soft-danger': `${row.value}` === 'Order Cancelled',
                                'badge-soft-success': `${row.value}` === 'Transaction Completed',
                                'badge-soft-warning': `${row.value}` === 'Transaction Processing'}"
                          >{{ row.value}}</div>
                        </template>
                        <template v-slot:cell(admin)="row">
                        <div
                        >{{row.item.admin_name}}</div>
                      </template>
                      <template v-slot:cell(action)="row">
                        <a
                        @click="download(row)"
                        href="javascript:void(0);"
                        :class="['mr-3',
                                  {'text-success': `${row.item.status}` == 'Transaction Completed',
                                    'text-danger' : `${row.item.status}` == 'Order Cancelled',
                                    'text-warning' : `${row.item.status}` == 'Transaction Processing'}]"
                        v-b-tooltip.hover
                        title="Invoice"
                      >
                        <i class="mdi mdi-download" style="font-size:1.7rem"></i>
                      </a>
                      <a
                        @click="detail(row)"
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        title="Details"
                      >
                        <i class="mdi mdi-file-eye" style="font-size:1.7rem"></i>
                      </a>
                      </template>
                      <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <h5 class='empty'>No Transaction Data ...</h5>
                </template>
                        <!-- <template v-slot:cell(invoice)="row">
                          <button @click="download(row)"
                          :class="['btn', 'btn-rounded',
                                  {'btn-success': `${row.item.status}` == 'Transaction Completed',
                                    'btn-danger' : `${row.item.status}` == 'Order Cancelled',
                                    'btn-light' : `${row.item.status}` == 'Transaction Processing'}]"
                          >
                            <i class="mdi mdi-download"></i>
                          </button>
                        </template>
                        <template v-slot:cell(detail)="row">
                          <button @click="detail(row)" class="btn btn-primary btn-rounded">
                            <i class="mdi mdi-file-eye"></i>
                          </button>
                        </template> -->
                      </b-table>
                    </div>
                  <!-- <template v-else>
                    <h5 class='empty'>No Transaction Data ...</h5>
                  </template> -->
                    <div class="row">
                      <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Detail Transaction"
      title-class="text-dark font-18"
      scrollable
      hide-footer
      centered
      size="xl"
    >
      <form>
        <template>
          <b-row class="invoice-preview">
            <b-col cols="12" xl="12" md="12">
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding pb-0">
                  <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                    <div class="header-invoice">
                      <div class="logo-wrapper">
                        <img class="img" src="@/assets/images/logo adaremit.png" alt="">
                        <!-- <h3 class="text-primary invoice-logo">
                            Adaremit
                        </h3> -->
                      </div>
                      <p class="card-text pl-2">Jl. Iming 4T Medan</p>
                      <p class="card-text pl-2">Indonesia</p>
                      <p class="card-text pl-2">+62 811 6028 665</p>
                      <p class="card-text pl-2 mb-2">help@adaremit.co.id</p>
                    </div>
                    <div class="invoice-info mt-md-0 mt-2">
                      <h4 class="invoice-title">Invoice
                        <span class="invoice-number ml-5">#{{inv.invoice_num}}</span>
                      </h4>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">Date</p>
                        <p class="invoice-date">{{inv.created}}</p>
                      </div>
                        <p class="invoice-date-title" style="margin-top:2rem" v-if="inv.bank_ref">Bank Reference :</p>
                        <p class="invoice-date" style="margin-top:-1rem" v-if="inv.bank_ref"><strong>{{inv.bank_ref}}</strong></p>
                    </div>
                  </div>
                </b-card-body>
                <hr class="invoice-spacing">

                <b-card-body class="invoice-padding pt-0">
                    <b-row class="invoice-spacing">
                        <b-col cols="12" xl="6" class="invoice-sender pb-5">
                            <h5 class="card-text">Sender :</h5>
                            <h6 class="card-text name">{{inv.sender_name ? inv.sender_name.toUpperCase() : '-'}}</h6>
                            <p class="card-text">{{inv.user_address}}</p>
                            <p class="card-text">{{inv.user_city == 'Others' ? '' : inv.user_city}}</p>
                            <p class="card-text">{{inv.user_prov == 'Others' ? '' : inv.user_prov}}</p>
                            <p class="card-text">{{inv.user_country}}</p>
                        </b-col>
                        <b-col xl="6" cols="12" class="invoice-recipient pb-0 pr-0">
                            <h5 class="mb-2">Recipient :</h5>
                            <h6 class="card-text name mb-1">{{inv.recipient_name ? inv.recipient_name.toUpperCase() : '-'}}</h6>
                            <table>
                                <tbody>
                                    <tr v-if="inv.acc_number"><td class="pr-3">{{inv.des_country == 'Argentina' ? 'CBU' :'Account Number'}}</td><td>{{inv.acc_number}}</td></tr>
                                    <tr v-if="inv.iban"><td class="pr-3">IBAN</td><td>{{inv.iban}}</td></tr>
                                    <tr v-if="inv.phone_n"><td class="pr-3">Phone</td><td>{{inv.phone_n}}</td></tr>
                                    <tr v-if="inv.bank_name"><td class="pr-3">Bank name</td><td>{{inv.usd_bank ? inv.usd_bank + ' (' + inv.bank_name + ')'  : inv.bank_name}}</td></tr>
                                    <tr v-if="inv.bsb_code"><td class="pr-3">BSB</td><td>{{inv.bsb_code}}</td></tr>
                                    <tr v-if="inv.transit_code"><td class="pr-3">Transit Code</td><td>{{inv.transit_code}}</td></tr>
                                    <tr v-if="inv.branch_name"><td class="pr-3">Branch Name</td><td>{{inv.branch_name}}</td></tr>
                                    <tr v-if="inv.branch_code"><td class="pr-3">Branch Code</td><td>{{inv.branch_code}}</td></tr>
                                    <tr v-if="inv.ifsc"><td class="pr-3">IFSC</td><td>{{inv.ifsc}}</td></tr>
                                    <tr v-if="inv.bik_code"><td class="pr-3">BIK Code</td><td>{{inv.bik_code}}</td></tr>
                                    <tr v-if="inv.swift"><td class="pr-3">SWIFT</td><td>{{inv.swift}}</td></tr>
                                    <tr v-if="inv.address"><td class="pr-3">Address</td><td>{{inv.address}}</td></tr>
                                    <tr v-if="inv.bank_add"><td class="pr-3">Bank Address</td><td>{{inv.bank_add}}</td></tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                </b-card-body>

                <hr class="invoice-spacing">
                <b-card-body class="invoice-padding pt-0">
                    <h5 class="title-send">Send details :</h5>
                    <b-row class="invoice-spacing">
                        <b-col cols="4" xl="4" class="invoice-send">
                            <table>
                                <tbody>
                                    <tr><td class="pr-4">Purpose</td><td>{{inv.purpose}}</td></tr>
                                    <tr><td class="pr-4">Source</td><td>{{inv.source}}</td></tr>
                                    <tr v-if="inv.note"><td class="pr-4">Note</td><td>{{inv.note}}</td></tr>
                                </tbody>
                            </table>
                        </b-col>
                        <b-col cols="5" xl="5" class="invoice-send">
                            <div class="invoice-total-wrapper">
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title">Destination Country</p>
                                    <p class="invoice-total-amount">{{inv.des_country}}</p>
                                </div>
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title">Amount</p>
                                    <p class="invoice-total-amount">{{inv.curr_code ? inv.curr_code.substring(0,3) : '-'}} {{Number(inv.amount) %1 != 0 ? formatPrice(inv.amount) : formatPriceRP(inv.amount)}}</p>
                                </div>
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title">Rate</p>
                                    <p class="invoice-total-amount">IDR {{inv.des_country == 'Vietnam' ? formatPriceVND(inv.rate) : Number(inv.rate) >= 1000 ? formatPriceRP(inv.rate) : formatPrice(inv.rate) }}</p>
                                </div>
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title">Fee</p>
                                    <p class="invoice-total-amount">IDR {{formatPriceRP(inv.fee)}}</p>
                                </div>
                                <hr class="my-50">
                                <div class="invoice-total-item strong">
                                    <p class="invoice-total-title">Total</p>
                                    <p class="invoice-total-amount">IDR {{formatPriceRP(Number(inv.send) + Number(inv.fee))}}</p>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="3" class="mt-md-6">
                            <div class="invoice-total-wrapper">
                                <div class="invoice-total-item">
                                    <img v-if="inv.status === 'Transaction Completed'" src="../../assets/images/success.svg" width="200rem" class="ml-5" alt="">
                                    <img v-if="inv.status === 'Order Cancelled'" src="../../assets/images/cancelled.svg" width="200rem"  class="ml-5" alt="">
                                    <img v-if="inv.status === 'Transaction Processing'" src="../../assets/images/proces.svg" width="200rem"  class="ml-5" alt="">
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>

                <!-- <hr class="invoice-spacing">

                <b-card-body class="invoice-padding pt-0 pl-5">
                    <span class="font-weight-bold">Note: </span>
                    <span>If you have any questions about this invoice, please contact us</span>
                </b-card-body> -->
              </b-card>
            </b-col>
          </b-row>
          <b-row class="invoice-small"><p>Open in Bigger Screen!!</p></b-row>
        </template>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>
<style scoped>
.invoice-small {
  display: none;
}
@media (max-width: 1000px) {
  .invoice-preview {
    display: none;
  }
  .invoice-small {
    display: block;
    margin-left:25%;
    font-weight: 900;
    font-size: 1.2rem;
    color: lightgray;
  }
}
</style>